@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?c2d6ac80da2d203a8f83277257c91a60") format("truetype"), url("./flaticon.woff?c2d6ac80da2d203a8f83277257c91a60") format("woff"), url("./flaticon.woff2?c2d6ac80da2d203a8f83277257c91a60") format("woff2"), url("./flaticon.eot?c2d6ac80da2d203a8f83277257c91a60#iefix") format("embedded-opentype"), url("./flaticon.svg?c2d6ac80da2d203a8f83277257c91a60#flaticon") format("svg");
}

i[class^="flaticon-"]:before,
i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-download:before {
    content: "\f101";
}

.flaticon-down-arrow-1:before {
    content: "\f102";
}

.flaticon-up-arrow:before {
    content: "\f103";
}

.flaticon-upload:before {
    content: "\f104";
}

.flaticon-right-arrow:before {
    content: "\f105";
}

.flaticon-right:before {
    content: "\f106";
}

.flaticon-right-arrow-2:before {
    content: "\f107";
}

.flaticon-left-arrow:before {
    content: "\f108";
}

.flaticon-left:before {
    content: "\f109";
}

.flaticon-plus:before {
    content: "\f10a";
}

.flaticon-plus-1:before {
    content: "\f10b";
}

.flaticon-multiply:before {
    content: "\f10c";
}

.flaticon-multiply-1:before {
    content: "\f10d";
}