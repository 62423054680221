::after,
.swiper-button-next,
.swiper-button-prev {
  color: #131313; 
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}
