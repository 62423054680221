@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@500&display=swap');

*,
::before,
::after {
    box-sizing: border-box;

    border-width: 0;

    border-style: solid;

    border-color: currentColor;

}

::before,
::after {
    --tw-content: '';
}



html {
    line-height: 1.5;

    -webkit-text-size-adjust: 100%;

    -moz-tab-size: 4;

    -o-tab-size: 4;
    tab-size: 4;

    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

[type='checkbox'],
[type='radio'] {
    color: rgb(184 145 72) !important;
}

[type='checkbox']:focus,
[type='radio']:focus {
    --tw-ring-color: rgb(184 145 72) !important;
}

input#name:focus,
input#number:focus {
    border: 1px solid rgb(184 145 72) !important;
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

body {
    margin: 0;

    line-height: inherit;

}



hr {
    height: 0;

    color: inherit;

    border-top-width: 1px;

}



abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
}



h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}


a {
    color: inherit;
    text-decoration: inherit;
}



b,
strong {
    font-weight: bolder;
}



code,
kbd,
samp,
pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

    font-size: 1em;

}


small {
    font-size: 80%;
}



sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}



table {
    text-indent: 0;

    border-color: inherit;

    border-collapse: collapse;

}



button,
input,
optgroup,
select,
textarea {
    font-family: inherit;

    font-size: 100%;

    line-height: inherit;

    color: inherit;

    margin: 0;

    padding: 0;

}



button,
select {
    text-transform: none;
}



button,
[type='button'],
[type='reset'],
[type='submit'] {
    appearance: button;

    background-color: transparent;

    background-image: none;

}



:-moz-focusring {
    outline: auto;
}


:-moz-ui-invalid {
    box-shadow: none;
}


progress {
    vertical-align: baseline;
}



::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}




[type='search'] {
    appearance: textfield;

    outline-offset: -2px;

}



::-webkit-search-decoration {
    -webkit-appearance: none;
}



::-webkit-file-upload-button {
    -webkit-appearance: button;

    font: inherit;

}



summary {
    display: list-item;
}



blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0;
}

fieldset {
    margin: 0;
    padding: 0;
}

legend {
    padding: 0;
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0;
}



textarea {
    resize: vertical;
}



input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;

    color: #9ca3af;

}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;

    color: #9ca3af;

}

input::placeholder,
textarea::placeholder {
    opacity: 1;

    color: #9ca3af;

}


button,
[role="button"] {
    cursor: pointer;
}



:disabled {
    cursor: default;
}



img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;

}



img,
video {
    max-width: 100%;
    height: auto;
}


[hidden] {
    display: none;
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 #0000;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #2563eb;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #6b7280;
    opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #6b7280;
    opacity: 1;
}

input::placeholder,
textarea::placeholder {
    color: #6b7280;
    opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
}

::-webkit-date-and-time-value {
    min-height: 1.5em;
}

select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;

}

[multiple] {
    background-image: initial;
    background-position: initial;
    background-repeat: unset;
    background-size: initial;
    padding-right: 0.75rem;

}

[type='checkbox'],
[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;

    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    --tw-shadow: 0 0 #0000;
}

[type='checkbox'] {
    border-radius: 0px;
}

[type='radio'] {
    border-radius: 100%;
}

[type='checkbox']:focus,
[type='radio']:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

[type='checkbox']:checked,
[type='radio']:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

[type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type='radio']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type='checkbox']:checked:hover,
[type='checkbox']:checked:focus,
[type='radio']:checked:hover,
[type='radio']:checked:focus {
    border-color: transparent;
    background-color: currentColor;
}

[type='checkbox']:indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

[type='checkbox']:indeterminate:hover,
[type='checkbox']:indeterminate:focus {
    border-color: transparent;
    background-color: currentColor;
}

[type='file'] {
    background: unset;
    border-color: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-size: unset;
    line-height: inherit;
}

[type='file']:focus {
    outline: 1px auto -webkit-focus-ring-color;
}

body {
    font-family: 'Assistant', sans-serif;
}










h1,
h2,
h3,
h4,
h5,
h6 {
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

h1 {
    font-size: 3rem;
    line-height: 1;
    font-weight: 700;
    line-height: 60px;
}

@media (min-width: 768px) {
    h1 {
        font-size: 3.75rem;
        line-height: 1;
    }

    h1 {
        line-height: 80px;
    }
}

@media (min-width: 1024px) {
    h1 {
        font-size: 82px;
    }

    h1 {
        line-height: 100px;
    }
}

@media (min-width: 1280px) {
    h1 {
        line-height: 106.6px;
    }
}

h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;
}

@media (min-width: 1280px) {
    h2 {
        font-size: 44px;
    }

    h2 {
        line-height: 61.6px;
    }
}

h3,
h4 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
}

@media (min-width: 1024px) {

    h3,
    h4 {
        font-size: 34px;
    }

    h3,
    h4 {
        line-height: 51px;
    }
}

.plain-text {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    line-height: 36px;
}

input {
    display: block;
    width: 100%;
}

input:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.section-padding {
    padding-top: 65px;
    padding-bottom: 65px;
}

@media (min-width: 768px) {
    .section-padding {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

@media (min-width: 1024px) {
    .section-padding {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }
}

@media (min-width: 1280px) {
    .section-padding {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.section-padding-top {
    padding-top: 65px;
}

@media (min-width: 768px) {
    .section-padding-top {
        padding-top: 5rem;
    }
}

@media (min-width: 1024px) {
    .section-padding-top {
        padding-top: 7rem;
    }
}

@media (min-width: 1280px) {
    .section-padding-top {
        padding-top: 130px;
    }
}

.section-padding-bottom {
    padding-bottom: 65px;
}

@media (min-width: 768px) {
    .section-padding-bottom {
        padding-bottom: 5rem;
    }
}

@media (min-width: 1024px) {
    .section-padding-bottom {
        padding-bottom: 7rem;
    }
}

@media (min-width: 1280px) {
    .section-padding-bottom {
        padding-bottom: 130px;
    }
}

.column-title {
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
    line-height: 2.25rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
    .column-title {
        font-size: 38px;
    }

    .column-title {
        line-height: 56px;
    }
}

@media (min-width: 1024px) {
    .column-title {
        font-size: 44px;
    }

    .column-title {
        line-height: 61.6px;
    }
}

.mini-title {
    margin-bottom: 0.25rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(184 145 72 / var(--tw-text-opacity));
}

.shape-bg {
    display: inline-block;
    background-image: url('../images/svg/line-shape.svg');
    background-repeat: no-repeat;
    padding-bottom: 0.5rem;
    --tw-text-opacity: 1;
    color: rgb(184 145 72 / var(--tw-text-opacity));
    background-size: 100%;
    background-position: center bottom;
}

.shape {
    color: rgb(184 145 72 / var(--tw-text-opacity))
}

.shape-bg.mini {
    background-image: url('../images/svg/mini-shape.svg');
}

*,
::before,
::after {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

.\!container {
    width: 100% !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .\!container {
        max-width: 640px !important;
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }

    .\!container {
        max-width: 768px !important;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .\!container {
        max-width: 1024px !important;
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}

@media (min-width: 1140px) {
    .container {
        max-width: 1140px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .\!container {
        max-width: 1140px !important;
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}

@media (min-width: 1170px) {
    .container {
        max-width: 1170px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .\!container {
        max-width: 1170px !important;
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}

.aspect-w-1 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 1;
}

.aspect-w-1>* {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.aspect-h-2 {
    --tw-aspect-h: 2;
}

.aspect-w-3 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 3;
}

.aspect-w-3>* {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.aspect-h-4 {
    --tw-aspect-h: 4;
}

.btn {
    display: inline-block;
    border-radius: 0.375rem;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
}

@media (min-width: 1024px) {
    .btn {
        padding-left: 32px;
        padding-right: 32px;
    }

    .btn {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .btn {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
}

.btn-lg {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.btn-md {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.btn-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn-primary:hover {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(184 145 72 / var(--tw-ring-opacity));
    --tw-ring-offset-width: 2px;
}

.btn-black {
    --tw-bg-opacity: 1;
    background-color: rgb(12 19 39 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn-black:hover {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(12 19 39 / var(--tw-ring-opacity));
    --tw-ring-offset-width: 2px;
}

.mian-menu>ul>li.active>a,
.mian-menu ul>li:hover>a {
    color: #fff !important;
    background: #9b3c38;
}

.\!mian-menu>ul>li.\!active>a,
.\!mian-menu ul>li:hover>a {
    color: #fff !important;
    background: #9b3c38 !important;
}

.mian-menu>ul>li.active>a,
.mian-menu ul>li:hover>a {
    border-radius: 0.375rem;
}

.\!mian-menu>ul>li.\!active>a,
.\!mian-menu ul>li:hover>a {
    border-radius: 0.375rem;
}

.mian-menu ul>li.menu-item-has-children:hover>ul,
.mian-menu ul>li.menu-item-has-children:hover>.mega-menu {
    visibility: visible;
    top: 105%;
    opacity: 1;
}

.mian-menu ul ul li.menu-item-has-children:hover>ul {
    visibility: visible;
    top: 20px;
    opacity: 1;
}

.active-price span {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.active-price span.active {
    height: 100%;
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.\!active-price span.\!active {
    height: 100%;
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.active-price .form-check:checked~span {
    height: 100%;
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.active-price .form-check:checked~span.active {
    height: 100%;
    border-radius: 0.375rem;
    background-color: transparent;
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

.\!active-price .\!form-check:checked~span.\!active {
    height: 100%;
    border-radius: 0.375rem;
    background-color: transparent;
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

.filter-list li {
    cursor: pointer;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-list li:hover {
    --tw-text-opacity: 1;
    color: rgb(184 145 72 / var(--tw-text-opacity));
}

.filter-list li.active {
    --tw-text-opacity: 1;
    /* color: rgb(184 145 72 / var(--tw-text-opacity)); */
    color: #fff !important;
    background: rgb(184 145 72 / var(--tw-text-opacity)) !important;
    padding: 10px;
    border-radius: 12px;

}

.\!filter-list li.\!active {
    --tw-text-opacity: 1;
    color: rgb(184 145 72 / var(--tw-text-opacity));
}

.social-explore {
    display: none;
}

.explore-button:hover~.social-explore {
    display: flex;
}

.explore-button:hover {
    display: none;
}

.accrodains li {
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.accrodains li.open {
    --tw-shadow: 0px 30px 30px -10px rgba(0, 0, 0, 0.08);
    --tw-shadow-colored: 0px 30px 30px -10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.course-accrodain li {
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.course-accrodain li.open {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0px 30px 30px -10px rgba(0, 0, 0, 0.08);
    --tw-shadow-colored: 0px 30px 30px -10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.course-accrodain .extra-text {
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(123 123 138 / var(--tw-text-opacity));
}

.course-accrodain li.open .extra-text {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.accrodains li .accrodain-button .icon-pm {
    background: url("https://api.iconify.design/heroicons/plus-20-solid.svg?color=%230c1327&width=24&height=24") no-repeat center center / contain;
    height: 1.5rem;
    width: 1.5rem;
}

.course-accrodain li .accrodain-button .icon-pm {
    background: url("https://api.iconify.design/heroicons/chevron-right.svg?color=%230c1327&width=24&height=24") no-repeat center center / contain;
    height: 1.5rem;
    width: 1.5rem;
}

.course-accrodain li.open .accrodain-button .icon-pm {
    background: url("https://api.iconify.design/heroicons/chevron-down.svg?color=white&width=24&height=24") no-repeat center center / contain;
}

.accrodains li.open .accrodain-button .icon-pm {
    background: url("https://api.iconify.design/heroicons/x-mark-20-solid.svg?color=white&width=24&height=24") no-repeat center center / contain;
}

.accrodains li .accrodain-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
    .accrodains li .accrodain-button {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

.accrodains li.open .accrodain-button {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.course-accrodain li .accrodain-button {
    display: flex;
    width: 100%;
    align-items: center;
}

.course-accrodain li .accrodain-button> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.course-accrodain li .accrodain-button {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
    .course-accrodain li .accrodain-button {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

.course-accrodain li.open .accrodain-button {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.accrodains li .content {
    padding: 1.5rem;
}

.course-accrodain li .content {
    padding: 1.5rem;
}

.cata-Tbas li a {
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity));
    font-size: 1.5rem;
    line-height: 2rem;
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.cata-Tbas li.active a {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.\!cata-Tbas li.\!active a {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.wdiget {
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 2rem;
    --tw-shadow: 0px 10px 40px rgba(0, 0, 0, 0.07);
    --tw-shadow-colored: 0px 10px 40px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.widget-title {
    margin-bottom: 2rem;
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
    .widget-title {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

.form-check .form-check-input~.ck-box {
    height: 26px;
    width: 26px;
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity));
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.form-check .form-check-input~.ck-box img {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.form-check .form-check-input:checked~.ck-box img {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.form-check .form-check-input:checked~.ck-box {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
}

.course-tab {
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

@media (min-width: 1024px) {
    .course-tab {
        display: flex;
    }

    .course-tab> :not([hidden])~ :not([hidden]) {
        --tw-divide-x-reverse: 0;
        border-right-width: calc(2px * var(--tw-divide-x-reverse));
        border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
    }

    .course-tab> :not([hidden])~ :not([hidden]) {
        --tw-divide-opacity: 1;
        border-color: rgb(236 236 236 / var(--tw-divide-opacity));
    }
}

.course-tab li {
    flex: 1 1 0%;
}

.course-tab li a {
    display: block;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 15px;
    padding-bottom: 15px;
}

.course-tab li a:first-child {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.course-tab li a:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.course-tab li.active {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.course-tab li.active:first-child {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.course-tab li.active:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.\!course-tab li.\!active {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.\!course-tab li.\!active:first-child {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.\!course-tab li.\!active:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.from-control {
    display: block;
    width: 100%;
    border-radius: 0.25rem;
    border-style: none;
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity));
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 17px;
    padding-bottom: 17px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.from-control:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.main-menu>ul {
    margin: 0px;
    display: none;
    list-style-type: none;
    justify-content: center;
    padding: 0px;
}

@media (min-width: 1024px) {
    .main-menu>ul {
        display: flex;
    }
}

.main-menu ul li {
    position: relative;
}

.main-menu>ul>li {
    border-bottom-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(236 236 236 / var(--tw-border-opacity));
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.main-menu>ul>li:last-child {
    border-width: 0px;
}

@media (min-width: 1024px) {
    .main-menu>ul>li {
        border-style: none;
    }

    .main-menu>ul>li {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.main-menu>ul>li>a {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

@media (min-width: 1280px) {
    .main-menu>ul>li>a {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.main-menu>ul>li:hover>a {
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

.main-menu>ul>li.menu-item-has-children>a:after {
    position: absolute;
    content: "\f101";
    font-family: "flaticon";
    font-size: 11px;
    right: 1.25rem;
    margin-top: -32px;
    color: #6b7280;
}

@media (min-width: 1024px) {
    .main-menu>ul>li.menu-item-has-children>a:after {
        right: 0px;
    }
}

li.menu-item-has-children:hover>a:after {
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

.main-menu>ul>li.menu-item-has-children>ul.sub-menu {
    visibility: hidden;
    position: static;
    left: 0px;
    top: 110%;
    z-index: 99;
    display: none;
    width: 100%;
    min-width: 200px;
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    opacity: 0;
}

@media (min-width: 1024px) {
    .main-menu>ul>li.menu-item-has-children>ul.sub-menu {
        position: absolute;
    }

    .main-menu>ul>li.menu-item-has-children>ul.sub-menu {
        display: block;
    }

    .main-menu>ul>li.menu-item-has-children>ul.sub-menu {
        --tw-shadow: 0px 30px 30px -10px rgba(0, 0, 0, 0.08);
        --tw-shadow-colored: 0px 30px 30px -10px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .main-menu>ul>li.menu-item-has-children>ul.sub-menu {
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }

    .main-menu>ul>li.menu-item-has-children>ul.sub-menu {
        transition-duration: 200ms;
    }
}

.main-menu>ul>li.menu-item-has-children:hover>ul.sub-menu {
    visibility: visible;
    top: 100%;
    opacity: 1;
}

.main-menu>ul>li.menu-item-has-children ul li:first-child a {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.main-menu>ul>li.menu-item-has-children ul li:last-child a {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.main-menu>ul>li.menu-item-has-children>ul.sub-menu>li>a,
.main-menu ul ul li a {
    display: block;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1rem;
    line-height: 1.5rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.main-menu>ul>li.menu-item-has-children>ul.sub-menu>li:hover>a,
.main-menu ul ul>li:hover>a {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.main-menu>ul ul {
    visibility: hidden;
    position: absolute;
    left: 208px;
    top: 0px;
    min-width: 200px;
    opacity: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.main-menu>ul>li.menu-item-has-children>ul.sub-menu>li.menu-item-has-children:hover>ul.sub-menu,
.main-menu>ul>li.menu-item-has-children>ul.sub-menu>li.menu-item-has-children>ul.sub-menu>li.menu-item-has-children:hover>ul.sub-menu {
    visibility: visible;
    opacity: 1;
}

.main-menu ul>li.has-megamenu {
    position: static;
}

.main-menu>ul>li.menu-item-has-children:hover>.rt-mega-menu {
    visibility: visible;
    top: 100%;
    opacity: 1;
}

ul ul>li.menu-item-has-children>a:after {
    content: "\f105";
    font-family: "flaticon";
    position: absolute;
    font-size: 11px;
    top: 50%;
    right: 10px;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (min-width: 768px) {
    ul ul>li.menu-item-has-children>a:after {
        right: 1.5rem;
    }
}

ul ul>li.menu-item-has-children:hover>a {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

ul ul>li.menu-item-has-children:hover>a:after {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.modal-search {
    display: none;
}

.modal-open .modal-search {
    display: block;
    -webkit-animation: scale-up-ver-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-ver-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.modal-open .main-menu ul {
    display: none;
    -webkit-animation: scale-up-ver-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-ver-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-out-center {
    0% {
        transform: scaleY(0.4);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes scale-out-center {
    0% {
        transform: scaleY(0.4);
    }

    100% {
        transform: scaleY(1);
    }
}

.mobile-menu>ul>li {
    position: relative;
    margin-top: 0.5rem;
    display: block;
    border-bottom-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(236 236 236 / var(--tw-border-opacity));
    padding-bottom: 0.5rem;
}

.mobile-menu>ul>li:last-child {
    border-width: 0px;
}

.mobile-menu>ul>li:hover>a {
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

.mobile-menu ul li a {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

/* .mobile-menu>ul>li.menu-item-has-children>a:after {
    position: absolute;
    content: "\f105";
    font-family: "flaticon";
    font-size: 13px;
    right: 1.25rem;
} */

@media (min-width: 1024px) {
    .mobile-menu>ul>li.menu-item-has-children>a:after {
        right: 1rem;
    }
}

/* .mobile-menu>ul>li.menu-item-has-children.open>a:after {
    content: "\f101";
} */

.mobile-menu ul ul,
.mobile-menu .rt-mega-menu {
    visibility: visible;
    position: static;
    display: none;
    border-radius: 0px;
    opacity: 1;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition-property: none;
}

.mobile-menu ul ul>li {
    margin-top: 0.5rem;
    border-bottom-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(236 236 236 / var(--tw-border-opacity));
    padding-bottom: 0.5rem;
}

.mobile-menu ul ul>li:last-child {
    border-width: 0px;
}

.mobile-menu ul ul>li>a {
    padding-left: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
}

.mobile-menu ul ul>li>a:hover {
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

.openmobile-menu {
    left: -320px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
}

.openmobile-menu.active-mobile-menu {
    left: 0px;
}

.rt-mobile-menu-overlay {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    transition: all 0.24s ease-in-out;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
}

.rt-mobile-menu-overlay.active {
    z-index: 7;
    visibility: visible;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
}

.\!rt-mobile-menu-overlay.\!active {
    z-index: 7 !important;
    visibility: visible !important;
    opacity: 1 !important;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
    filter: alpha(opacity=100) !important;
}

.home-one-header {
    position: absolute;
}

.home-one-header.rt-sticky-active,
.header-normal.rt-sticky-active {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0px 10px 40px rgba(0, 0, 0, 0.06);
    --tw-shadow-colored: 0px 10px 40px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.pointer-events-none {
    pointer-events: none;
}

.invisible {
    visibility: hidden;
}

.static {
    position: static;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.sticky {
    position: sticky;
}

.inset-\[11px\] {
    top: 11px;
    right: 11px;
    bottom: 11px;
    left: 11px;
}

.inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.inset-y-4 {
    top: 1rem;
    bottom: 1rem;
}

.inset-x-2 {
    left: 0.5rem;
    right: 0.5rem;
}

.top-0 {
    top: 0px;
}

.left-0 {
    left: 0px;
}

.top-1\/2 {
    top: 50%;
}

.left-1\/2 {
    left: 50%;
}

.-top-10 {
    top: -2.5rem;
}

.right-\[7\%\] {
    right: 7%;
}

.top-\[20\%\] {
    top: 20%;
}

.bottom-0 {
    bottom: 0px;
}

.left-6 {
    left: 1.5rem;
}

.top-6 {
    top: 1.5rem;
}

.top-\[3px\] {
    top: 3px;
}

.top-1 {
    top: 0.25rem;
}

.right-\[-60px\] {
    right: -60px;
}

.right-8 {
    right: 2rem;
}

.bottom-12 {
    bottom: 3rem;
}

.left-16 {
    left: 4rem;
}

.z-\[9\] {
    z-index: 9;
}

.z-\[999\] {
    z-index: 999;
}

.z-\[1\] {
    z-index: 1;
}

.z-\[-1\] {
    z-index: -1;
}

.z-30 {
    z-index: 30;
}

.order-last {
    order: 9999;
}

.order-2 {
    order: 2;
}

.col-span-12 {
    grid-column: span 12 / span 12;
}

.col-span-1 {
    grid-column: span 1 / span 1;
}

.col-span-3 {
    grid-column: span 3 / span 3;
}

.col-start-1 {
    grid-column-start: 1;
}

.col-end-4 {
    grid-column-end: 4;
}

.row-span-2 {
    grid-row: span 2 / span 2;
}

.row-start-3 {
    grid-row-start: 3;
}

.row-end-5 {
    grid-row-end: 5;
}

.float-right {
    float: right;
}

.clear-left {
    clear: left;
}

.m-4 {
    margin: 1rem;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.mr-6 {
    margin-right: 1.5rem;
}

.mt-4 {
    margin-top: 1rem;
}

.mr-10 {
    margin-right: 2.5rem;
}

.mt-6 {
    margin-top: 1.5rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mb-14 {
    margin-bottom: 3.5rem;
}

.mb-6 {
    margin-bottom: 1.5rem;
}

.mb-10 {
    margin-bottom: 2.5rem;
}

.mb-8 {
    margin-bottom: 2rem;
}

.mb-4 {
    margin-bottom: 1rem;
}

.mb-1 {
    margin-bottom: 0.25rem;
}

.mt-12 {
    margin-top: 3rem;
}

.mr-3 {
    margin-right: 0.75rem;
}

.mb-12 {
    margin-bottom: 3rem;
}

.mb-20 {
    margin-bottom: 5rem;
}

.mb-\[50px\] {
    margin-bottom: 50px;
}

.mb-5 {
    margin-bottom: 1.25rem;
}

.mt-14 {
    margin-top: 3.5rem;
}

.mt-8 {
    margin-top: 2rem;
}

.ml-4 {
    margin-left: 1rem;
}

.ml-14 {
    margin-left: 3.5rem;
}

.mt-\[30px\] {
    margin-top: 30px;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mb-3 {
    margin-bottom: 0.75rem;
}

.mt-10 {
    margin-top: 2.5rem;
}

.mr-\[74px\] {
    margin-right: 74px;
}

.mr-4 {
    margin-right: 1rem;
}

.mt-\[60px\] {
    margin-top: 60px;
}

.mt-3 {
    margin-top: 0.75rem;
}

.-mt-\[150px\] {
    margin-top: -150px;
}

.ml-auto {
    margin-left: auto;
}

.mb-\[15px\] {
    margin-bottom: 15px;
}

.mt-24 {
    margin-top: 6rem;
}

.mt-20 {
    margin-top: 5rem;
}

.mt-5 {
    margin-top: 1.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-1 {
    margin-top: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.mt-0 {
    margin-top: 0px;
}

.mt-\[clamp\(30px\2c 100px\)\] {
    margin-top: clamp(30px, 100px);
}

.mr-1 {
    margin-right: 0.25rem;
}

.mb-0 {
    margin-bottom: 0px;
}

.box-border {
    box-sizing: border-box;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.table {
    display: table;
}

.grid {
    display: grid;
}

.inline-grid {
    display: inline-grid;
}

.list-item {
    display: list-item;
}

.hidden {
    display: none;
}

.h-\[46px\] {
    height: 46px;
}

.h-full {
    height: 100%;
}

.h-10 {
    height: 2.5rem;
}

.h-screen {
    height: 100vh;
}

.h-12 {
    height: 3rem;
}

.h-\[6px\] {
    height: 6px;
}

.h-\[72px\] {
    height: 72px;
}

.h-\[270px\] {
    height: 270px;
}

.h-20 {
    height: 5rem;
}

.h-\[32px\] {
    height: 32px;
}

.h-\[60\%\] {
    height: 60%;
}

.h-\[400px\] {
    height: 400px;
}

.h-\[80px\] {
    height: 80px;
}

.h-\[170px\] {
    height: 170px;
}

.h-\[260px\] {
    height: 260px;
}

.h-\[3px\] {
    height: 3px;
}

.h-8 {
    height: 2rem;
}

.h-\[35px\] {
    height: 35px;
}

.h-\[420px\] {
    height: 420px;
}

.h-\[60px\] {
    height: 60px;
}

.h-\[248px\] {
    height: 248px;
}

.h-\[159px\] {
    height: 159px;
}

.h-4 {
    height: 1rem;
}

.h-\[297px\] {
    height: 297px;
}

.h-\[182px\] {
    height: 182px;
}

.h-\[150px\] {
    height: 150px;
}

.h-\[350px\] {
    height: 350px;
}

.h-\[310px\] {
    height: 310px;
}

.h-\[220px\] {
    height: 220px;
}

.h-24 {
    height: 6rem;
}

.h-\[3\.23rem\] {
    height: 3.23rem;
}

.h-\[calc\(100\%\+1rem\)\] {
    height: calc(100% + 1rem);
}

.h-\[var\(--height\)\] {
    height: var(--height);
}

.h-16 {
    height: 4rem;
}

.max-h-\[3\.23rem\] {
    max-height: 3.23rem;
}

.max-h-\[calc\(100\%\+1rem\)\] {
    max-height: calc(100% + 1rem);
}

.max-h-\[var\(--height\)\] {
    max-height: var(--height);
}

.max-h-screen {
    max-height: 100vh;
}

.min-h-screen {
    min-height: 100vh;
}

.min-h-\[219px\] {
    min-height: 219px;
}

.min-h-\[3\.23rem\] {
    min-height: 3.23rem;
}

.min-h-\[calc\(100\%\+1rem\)\] {
    min-height: calc(100% + 1rem);
}

.min-h-\[var\(--height\)\] {
    min-height: var(--height);
}

.min-h-0 {
    min-height: 0px;
}

.w-full {
    width: 100%;
}

.w-10 {
    width: 2.5rem;
}

.w-\[320px\] {
    width: 320px;
}

.w-12 {
    width: 3rem;
}

.w-\[72px\] {
    width: 72px;
}

.w-20 {
    width: 5rem;
}

.w-\[32px\] {
    width: 32px;
}

.w-\[80px\] {
    width: 80px;
}

.w-\[170px\] {
    width: 170px;
}

.w-8 {
    width: 2rem;
}

.w-\[60px\] {
    width: 60px;
}

.w-\[159px\] {
    width: 159px;
}

.w-4 {
    width: 1rem;
}

.w-\[150px\] {
    width: 150px;
}

.w-\[270px\] {
    width: 270px;
}

.w-\[3\.23rem\] {
    width: 3.23rem;
}

.w-\[calc\(100\%\+1rem\)\] {
    width: calc(100% + 1rem);
}

.w-\[var\(--width\)\] {
    width: var(--width);
}

.min-w-\[272px\] {
    min-width: 272px;
}

.min-w-\[3\.23rem\] {
    min-width: 3.23rem;
}

.min-w-\[calc\(100\%\+1rem\)\] {
    min-width: calc(100% + 1rem);
}

.min-w-\[var\(--width\)\] {
    min-width: var(--width);
}

.min-w-min {
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
}

.max-w-\[120px\] {
    max-width: 120px;
}

.max-w-\[570px\] {
    max-width: 570px;
}

.max-w-\[337px\] {
    max-width: 337px;
}

.max-w-\[1112px\] {
    max-width: 1112px;
}

.max-w-\[327px\] {
    max-width: 327px;
}

.max-w-\[90\%\] {
    max-width: 90%;
}

.max-w-\[254px\] {
    max-width: 254px;
}

.max-w-\[1170px\] {
    max-width: 1170px;
}

.max-w-\[590px\] {
    max-width: 590px;
}

.max-w-\[350px\] {
    max-width: 350px;
}

.max-w-\[300px\] {
    max-width: 300px;
}

.max-w-xl {
    max-width: 36rem;
}

.max-w-md {
    max-width: 28rem;
}

.max-w-4xl {
    max-width: 56rem;
}

.max-w-\[3\.23rem\] {
    max-width: 3.23rem;
}

.max-w-\[calc\(100\%\+1rem\)\] {
    max-width: calc(100% + 1rem);
}

.max-w-\[var\(--width\)\] {
    max-width: var(--width);
}

.max-w-full {
    max-width: 100%;
}

.flex-none {
    flex: none;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex-shrink {
    flex-shrink: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-grow {
    flex-grow: 1;
}

.flex-grow-0 {
    flex-grow: 0;
}

.table-fixed {
    table-layout: fixed;
}

.border-collapse {
    border-collapse: collapse;
}

.origin-top-right {
    transform-origin: top right;
}

.-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
    --tw-translate-x: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-4 {
    --tw-translate-x: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-6 {
    --tw-translate-y: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-3 {
    --tw-translate-x: -0.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[23deg\] {
    --tw-rotate: 23deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[2\.3rad\] {
    --tw-rotate: 2.3rad;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[401grad\] {
    --tw-rotate: 401grad;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[1\.5turn\] {
    --tw-rotate: 1.5turn;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-3 {
    --tw-rotate: 3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-y-12 {
    --tw-skew-y: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-12 {
    --tw-skew-x: 12deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes ping {

    75%,
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes ping {

    75%,
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.animate-none {
    -webkit-animation: none;
    animation: none;
}

@-webkit-keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.animate-spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

.cursor-pointer {
    cursor: pointer;
}

.select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.resize-none {
    resize: none;
}

.resize {
    resize: both;
}

.list-inside {
    list-style-position: inside;
}

.list-disc {
    list-style-type: disc;
}

.appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
}

.grid-flow-row {
    grid-auto-flow: row;
}

.auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-\[200px\2c repeat\(auto-fill\2c minmax\(15\%\2c 100px\)\)\2c 300px\] {
    grid-template-columns: 200px repeat(auto-fill, minmax(15%, 100px)) 300px;
}

.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.flex-col {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.place-content-start {
    place-content: start;
}

.place-items-end {
    place-items: end;
}

.content-center {
    align-content: center;
}

.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-center {
    align-items: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-items-end {
    justify-items: end;
}

.gap-7 {
    gap: 1.75rem;
}

.gap-10 {
    gap: 2.5rem;
}

.gap-5 {
    gap: 1.25rem;
}

.gap-\[30px\] {
    gap: 30px;
}

.gap-6 {
    gap: 1.5rem;
}

.gap-4 {
    gap: 1rem;
}

.gap-x-2 {
    -moz-column-gap: 0.5rem;
    column-gap: 0.5rem;
}

.gap-y-3 {
    row-gap: 0.75rem;
}

.space-x-\[18px\]> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18px * var(--tw-space-x-reverse));
    margin-left: calc(18px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-\[80px\]> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80px * var(--tw-space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-5> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-x-3> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-8> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-\[30px\]> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px * var(--tw-space-y-reverse));
}

.space-y-6> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-2> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-x-8> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-x-5> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-x-\[10px\]> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px * var(--tw-space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-3> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-x-\[9px\]> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9px * var(--tw-space-x-reverse));
    margin-left: calc(9px * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-\[19px\]> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(19px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(19px * var(--tw-space-y-reverse));
}

.space-x-\[20cm\]> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20cm * var(--tw-space-x-reverse));
    margin-left: calc(20cm * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-\[calc\(20\%-1cm\)\]> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(calc(20% - 1cm) * var(--tw-space-x-reverse));
    margin-left: calc(calc(20% - 1cm) * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-reverse> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 1;
}

.space-x-reverse> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 1;
}

.divide-x-2> :not([hidden])~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x> :not([hidden])~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y> :not([hidden])~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-y-4> :not([hidden])~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
}

.divide-x-0> :not([hidden])~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y-0> :not([hidden])~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.divide-dotted> :not([hidden])~ :not([hidden]) {
    border-style: dotted;
}

.divide-\[\#E4EEED\]> :not([hidden])~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(228 238 237 / var(--tw-divide-opacity));
}

.divide-white> :not([hidden])~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-divide-opacity));
}

.divide-opacity-25> :not([hidden])~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
}

.divide-opacity-50> :not([hidden])~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
}

.place-self-center {
    place-self: center;
}

.self-end {
    align-self: flex-end;
}

.justify-self-start {
    justify-self: start;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overscroll-contain {
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.overflow-ellipsis {
    text-overflow: ellipsis;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.break-words {
    overflow-wrap: break-word;
}

.rounded-md {
    border-radius: 0.375rem;
}

.rounded {
    border-radius: 0.25rem;
}

.rounded-\[2px\] {
    border-radius: 2px;
}

.rounded-\[8px\] {
    border-radius: 8px;
}

.rounded-full {
    border-radius: 9999px;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.rounded-t-\[8px\] {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.rounded-tl-md {
    border-top-left-radius: 0.375rem;
}

.border {
    border-width: 1px;
}

.border-0 {
    border-width: 0px;
}

.border-2 {
    border-width: 2px;
}

.border-\[2\.5px\] {
    border-width: 2.5px;
}

.border-y {
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.border-t {
    border-top-width: 1px;
}

.border-t-4 {
    border-top-width: 4px;
}

.border-b {
    border-bottom-width: 1px;
}

.border-t-0 {
    border-top-width: 0px;
}

.border-l-0 {
    border-left-width: 0px;
}

.border-r-0 {
    border-right-width: 0px;
}

.border-b-4 {
    border-bottom-width: 4px;
}

.border-l-2 {
    border-left-width: 2px;
}

.border-l-4 {
    border-left-width: 4px;
}

.border-b-2 {
    border-bottom-width: 2px;
}

.border-solid {
    border-style: solid;
}

.border-none {
    border-style: none;
}

.border-gray {
    --tw-border-opacity: 1;
    border-color: rgb(123 123 138 / var(--tw-border-opacity));
}

.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-transparent {
    border-color: transparent;
}

.border-\[\#B2E2DF\] {
    --tw-border-opacity: 1;
    border-color: rgb(178 226 223 / var(--tw-border-opacity));
}

.border-\[\#ECECEC\] {
    --tw-border-opacity: 1;
    border-color: rgb(236 236 236 / var(--tw-border-opacity));
}

.border-primary {
    --tw-border-opacity: 1;
    border-color: rgb(184 145 72 / var(--tw-border-opacity));
}

.border-black {
    --tw-border-opacity: 1;
    border-color: rgb(12 19 39 / var(--tw-border-opacity));
}

.border-\[\#E9D4D0\] {
    --tw-border-opacity: 1;
    border-color: rgb(233 212 208 / var(--tw-border-opacity));
}

.border-\[\#f00\] {
    --tw-border-opacity: 1;
    border-color: rgb(255 0 0 / var(--tw-border-opacity));
}

.border-opacity-\[0\.1\] {
    --tw-border-opacity: 0.1;
}

.border-opacity-10 {
    --tw-border-opacity: 0.1;
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[\#F8F8F8\] {
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}



.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
}

.bg-secondary {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
}

.bg-tertiary {
    --tw-bg-opacity: 1;
    background-color: rgb(255 188 31 / var(--tw-bg-opacity));
}

.bg-green-paste {
    --tw-bg-opacity: 1;
    background-color: rgb(233 251 250 / var(--tw-bg-opacity));
}

.bg-\[\#FFE8E8\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 232 232 / var(--tw-bg-opacity));
}

.bg-\[\#E8F5FF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(232 245 255 / var(--tw-bg-opacity));
}

.bg-\[\#E3F9F6\] {
    --tw-bg-opacity: 1;
    background-color: rgb(227 249 246 / var(--tw-bg-opacity));
}

.bg-\[\#EEE8FF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(238 232 255 / var(--tw-bg-opacity));
}

.bg-\[\#FFF6EB\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 246 235 / var(--tw-bg-opacity));
}

.bg-\[\#E8FFEA\] {
    --tw-bg-opacity: 1;
    background-color: rgb(232 255 234 / var(--tw-bg-opacity));
}

.bg-red-paste {
    --tw-bg-opacity: 1;
    background-color: rgb(255 232 232 / var(--tw-bg-opacity));
}

.bg-transparent {
    background-color: transparent;
}

.bg-\[\#ECECEC\] {
    --tw-bg-opacity: 1;
    background-color: rgb(236 236 236 / var(--tw-bg-opacity));
}

.bg-\[\#0f0\] {
    --tw-bg-opacity: 1;
    background-color: rgb(0 255 0 / var(--tw-bg-opacity));
}

.bg-\[\#ff0000\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 0 0 / var(--tw-bg-opacity));
}

.bg-\[\#0000ffcc\] {
    background-color: #0000ffcc;
}

.bg-\[rgb\(123\2c 123\2c 123\)\] {
    --tw-bg-opacity: 1;
    background-color: rgb(123 123 123 / var(--tw-bg-opacity));
}

.bg-\[rgba\(123\2c 123\2c 123\2c 0\.5\)\] {
    background-color: rgba(123, 123, 123, 0.5);
}

.bg-\[hsl\(0\2c 100\%\2c 50\%\)\] {
    --tw-bg-opacity: 1;
    background-color: hsl(0 100% 50% / var(--tw-bg-opacity));
}

.bg-\[hsla\(0\2c 100\%\2c 50\%\2c 0\.3\)\] {
    background-color: hsla(0, 100%, 50%, 0.3);
}

.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-opacity-\[0\.08\] {
    --tw-bg-opacity: 0.08;
}

.bg-opacity-10 {
    --tw-bg-opacity: 0.1;
}

.bg-opacity-40 {
    --tw-bg-opacity: 0.4;
}

.bg-opacity-\[0\.11\] {
    --tw-bg-opacity: 0.11;
}

.bg-opacity-20 {
    --tw-bg-opacity: 0.2;
}

.bg-opacity-50 {
    --tw-bg-opacity: 0.5;
}

.bg-\[url\(\'\.\.\/images\/all-img\/404-bg\.png\'\)\] {
    background-image: url('../images/all-img/404-bg.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/footer-bg-1\.png\'\)\] {
    background-image: url('../images/all-img/footer-bg-1.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/bred\.png\'\)\] {
    background-image: url('../images/all-img/bred.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-1\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-1.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-16\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-16.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-15\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-15.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-2\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-2.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-14\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-14.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/bg-ins-1\.png\'\)\] {
    background-image: url('../images/all-img/bg-ins-1.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/bg-ins-2\.png\'\)\] {
    background-image: url('../images/all-img/bg-ins-2.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-7\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-7.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-3\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-3.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/bb\.png\'\)\] {
    background-image: url('../images/all-img/bb.png');
}

.bg-\[url\(\'\.\.\/images\/banner\/1\.png\'\)\] {
    background-image: url('../images/banner/1.png');
}

.bg-\[url\(\'\.\.\/images\/banner\/shape\.svg\'\)\] {
    background-image: url('../images/banner/shape.svg');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-5\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-5.png');
}

.bg-\[url\(\'\.\.\/images\/banner\/2\.png\'\)\] {
    background-image: url('../images/banner/2.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-6\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-6.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-8\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-8.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-9\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-9.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-10\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-10.png');
}

.bg-\[url\(\'\.\.\/images\/banner\/3\.png\'\)\] {
    background-image: url('../images/banner/3.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-11\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-11.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-12\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-12.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/section-bg-13\.png\'\)\] {
    background-image: url('../images/all-img/section-bg-13.png');
}

.bg-\[url\(\'\.\.\/images\/all-img\/insbg\.png\'\)\] {
    background-image: url('../images/all-img/insbg.png');
}

.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(252 165 165 / 0));
}

.via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff, var(--tw-gradient-to, rgb(233 213 255 / 0));
}

.to-blue-400 {
    --tw-gradient-to: #60a5fa;
}

.bg-cover {
    background-size: cover;
}

.bg-contain {
    background-size: contain;
}

.bg-local {
    background-attachment: local;
}

.bg-clip-border {
    background-clip: border-box;
}

.bg-center {
    background-position: center;
}

.bg-bottom {
    background-position: bottom;
}

.bg-top {
    background-position: top;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.fill-current {
    fill: currentColor;
}

.stroke-current {
    stroke: currentColor;
}

.stroke-2 {
    stroke-width: 2;
}

.object-contain {
    -o-object-fit: contain;
    object-fit: contain;
}

.object-cover {
    -o-object-fit: cover;
    object-fit: cover;
}

.object-bottom {
    -o-object-position: bottom;
    object-position: bottom;
}

.p-10 {
    padding: 2.5rem;
}

.p-6 {
    padding: 1.5rem;
}

.p-8 {
    padding: 2rem;
}

.p-5 {
    padding: 1.25rem;
}

.p-0 {
    padding: 0px;
}

.p-\[30px\] {
    padding: 30px;
}

.p-3 {
    padding: 0.75rem;
}

.p-4 {
    padding: 1rem;
}

.p-20 {
    padding: 5rem;
}

.p-\[var\(--app-padding\)\] {
    padding: var(--app-padding);
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.py-\[15px\] {
    padding-top: 15px;
    padding-bottom: 15px;
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-\[10px\] {
    padding-top: 10px;
    padding-bottom: 10px;
}

.px-\[50px\] {
    padding-left: 50px;
    padding-right: 50px;
}

.py-\[65px\] {
    padding-top: 65px;
    padding-bottom: 65px;
}

.py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.py-\[4px\] {
    padding-top: 4px;
    padding-bottom: 4px;
}

.py-\[17px\] {
    padding-top: 17px;
    padding-bottom: 17px;
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.py-\[11px\] {
    padding-top: 11px;
    padding-bottom: 11px;
}

.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}

.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.px-\[10px\] {
    padding-left: 10px;
    padding-right: 10px;
}

.px-\[15px\] {
    padding-left: 15px;
    padding-right: 15px;
}

.py-\[18px\] {
    padding-top: 18px;
    padding-bottom: 18px;
}

.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
}

.px-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.pt-10 {
    padding-top: 2.5rem;
}

.pb-6 {
    padding-bottom: 1.5rem;
}

.pb-4 {
    padding-bottom: 1rem;
}

.pt-8 {
    padding-top: 2rem;
}

.pr-\[10px\] {
    padding-right: 10px;
}

.pl-6 {
    padding-left: 1.5rem;
}

.pb-12 {
    padding-bottom: 3rem;
}

.pt-\[64px\] {
    padding-top: 64px;
}

.pb-8 {
    padding-bottom: 2rem;
}

.pb-16 {
    padding-bottom: 4rem;
}

.pt-5 {
    padding-top: 1.25rem;
}

.pt-6 {
    padding-top: 1.5rem;
}

.pb-\[28px\] {
    padding-bottom: 28px;
}

.pl-8 {
    padding-left: 2rem;
}

.pb-10 {
    padding-bottom: 2.5rem;
}

.pl-3 {
    padding-left: 0.75rem;
}

.pt-14 {
    padding-top: 3.5rem;
}

.pt-28 {
    padding-top: 7rem;
}

.pb-14 {
    padding-bottom: 3.5rem;
}

.pl-4 {
    padding-left: 1rem;
}

.pr-4 {
    padding-right: 1rem;
}

.pt-7 {
    padding-top: 1.75rem;
}

.pt-\[70px\] {
    padding-top: 70px;
}

.pt-\[150px\] {
    padding-top: 150px;
}

.pt-20 {
    padding-top: 5rem;
}

.pt-3 {
    padding-top: 0.75rem;
}

.pt-1 {
    padding-top: 0.25rem;
}

.pr-2 {
    padding-right: 0.5rem;
}

.pb-3 {
    padding-bottom: 0.75rem;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.align-middle {
    vertical-align: middle;
}

.font-gilroy {
    font-family: "Assistant", serif;
}

.font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.text-5xl {
    font-size: 3rem;
    line-height: 1;
}

.text-\[22px\] {
    font-size: 22px;
}

.text-\[44px\] {
    font-size: 44px;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-1xl {
    font-size: 22px;
    line-height: 32px;
}

.text-\[34px\] {
    font-size: 34px;
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}

.text-\[2\.23rem\] {
    font-size: 2.23rem;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.\!font-bold {
    font-weight: 700 !important;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.not-italic {
    font-style: normal;
}

.ordinal {
    --tw-ordinal: ordinal;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-\[27px\] {
    line-height: 27px;
}

.leading-\[30px\] {
    line-height: 30px;
}

.leading-\[66px\] {
    line-height: 66px;
}

.leading-\[1\] {
    line-height: 1;
}

.leading-\[51px\] {
    line-height: 51px;
}

.leading-\[36px\] {
    line-height: 36px;
}

.leading-\[62px\] {
    line-height: 62px;
}

.leading-relaxed {
    line-height: 1.625;
}

.leading-5 {
    line-height: 1.25rem;
}

.tracking-tight {
    letter-spacing: -0.025em;
}

.text-gray {
    --tw-text-opacity: 1;
    color: rgb(123 123 138 / var(--tw-text-opacity));
}

.text-black {
    --tw-text-opacity: 1;
    color: rgb(12 19 39 / var(--tw-text-opacity));
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-primary {
    --tw-text-opacity: 1;
    color: rgb(184 145 72 / var(--tw-text-opacity));
}

.text-secondary {
    --tw-text-opacity: 1;
    color: rgb(184 145 72 / var(--tw-text-opacity));
}

.text-tertiary {
    --tw-text-opacity: 1;
    color: rgb(255 188 31 / var(--tw-text-opacity));
}

.text-\[\#E6E6E6\] {
    --tw-text-opacity: 1;
    color: rgb(230 230 230 / var(--tw-text-opacity));
}

.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity));
}

.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-opacity-10 {
    --tw-text-opacity: 0.1;
}

.underline {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.placeholder-green-300::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(134 239 172 / var(--tw-placeholder-opacity));
}

.placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(134 239 172 / var(--tw-placeholder-opacity));
}

.placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(134 239 172 / var(--tw-placeholder-opacity));
}

.placeholder-opacity-60::-moz-placeholder {
    --tw-placeholder-opacity: 0.6;
}

.placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
}

.placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
}

.opacity-0 {
    opacity: 0;
}

.opacity-90 {
    opacity: 0.9;
}

.shadow-box2 {
    --tw-shadow: 0px 10px 40px rgba(0, 0, 0, 0.06);
    --tw-shadow-colored: 0px 10px 40px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-e1 {
    --tw-shadow: 0px 25px 55px -10px rgba(207, 174, 167, 0.2);
    --tw-shadow-colored: 0px 25px 55px -10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-box {
    --tw-shadow: 0px 35px 65px -10px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 35px 65px -10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-box7 {
    --tw-shadow: 0px 10px 40px rgba(0, 0, 0, 0.07);
    --tw-shadow-colored: 0px 10px 40px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-box3 {
    --tw-shadow: 0px 10px 40px rgba(0, 0, 0, 0.08);
    --tw-shadow-colored: 0px 10px 40px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-box10 {
    --tw-shadow: 0px 15px 45px -5px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0px 15px 45px -5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-box8 {
    --tw-shadow: 0px 35px 55px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 35px 55px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-box12 {
    --tw-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 15px 30px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-box5 {
    --tw-shadow: 0px 15px 30px rgba(0, 0, 0, 0.03);
    --tw-shadow-colored: 0px 15px 30px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-box14 {
    --tw-shadow: 0px 15px 100px -10px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 15px 100px -10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-box9 {
    --tw-shadow: 0px 15px 35px -10px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 15px 35px -10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.outline {
    outline-style: solid;
}

.outline-black {
    outline-color: #0C1327;
}

.ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.ring-opacity-40 {
    --tw-ring-opacity: 0.4;
}

.ring-offset-2 {
    --tw-ring-offset-width: 2px;
}

.ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
}

.grayscale-\[80\] {
    --tw-grayscale: grayscale(80);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter-none {
    filter: none;
}

.transition {
    transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.delay-300 {
    transition-delay: 300ms;
}

.duration-150 {
    transition-duration: 150ms;
}

.duration-100 {
    transition-duration: 100ms;
}

.duration-300 {
    transition-duration: 300ms;
}

.duration-\[2s\] {
    transition-duration: 2s;
}

.duration-\[var\(--app-duration\)\] {
    transition-duration: var(--app-duration);
}

.duration-200 {
    transition-duration: 200ms;
}

.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.marker\:text-secondary *::marker {
    --tw-text-opacity: 1;
    color: rgb(184 145 72 / var(--tw-text-opacity));
}

.marker\:text-secondary::marker {
    --tw-text-opacity: 1;
    color: rgb(184 145 72 / var(--tw-text-opacity));
}

.placeholder\:text-\[\#B2E2DF\]::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(178 226 223 / var(--tw-text-opacity));
}

.placeholder\:text-\[\#B2E2DF\]:-ms-input-placeholder {
    --tw-text-opacity: 1;
    color: rgb(178 226 223 / var(--tw-text-opacity));
}

.placeholder\:text-\[\#B2E2DF\]::placeholder {
    --tw-text-opacity: 1;
    color: rgb(178 226 223 / var(--tw-text-opacity));
}

.first\:pt-0:first-child {
    padding-top: 0px;
}

.first\:shadow-md:first-child {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.last\:mb-0:last-child {
    margin-bottom: 0px;
}

.last\:mr-0:last-child {
    margin-right: 0px;
}

.last\:border-0:last-child {
    border-width: 0px;
}

.last\:pb-0:last-child {
    padding-bottom: 0px;
}

.last\:shadow-md:last-child {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.odd\:shadow-md:nth-child(odd) {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.even\:shadow-md:nth-child(even) {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.visited\:shadow-md:visited {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.checked\:shadow-md:checked {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:container:hover {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 640px) {
    .hover\:container:hover {
        max-width: 640px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (min-width: 768px) {
    .hover\:container:hover {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .hover\:container:hover {
        max-width: 1024px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (min-width: 1140px) {
    .hover\:container:hover {
        max-width: 1140px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (min-width: 1170px) {
    .hover\:container:hover {
        max-width: 1170px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-secondary:hover {
    --tw-border-opacity: 1;
    border-color: rgb(184 145 72 / var(--tw-border-opacity));
}

.hover\:border-primary:hover {
    --tw-border-opacity: 1;
    border-color: rgb(184 145 72 / var(--tw-border-opacity));
}

.hover\:bg-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-secondary:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(184 145 72 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#8861DB\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(136 97 219 / var(--tw-bg-opacity));
}

.hover\:\!text-center:hover {
    text-align: center !important;
}

.hover\:font-bold:hover {
    font-weight: 700;
}

.hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-primary:hover {
    --tw-text-opacity: 1;
    color: rgb(184 145 72 / var(--tw-text-opacity));
}

.hover\:underline:hover {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
}

.hover\:shadow-box4:hover {
    --tw-shadow: 0px 45px 55px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0px 45px 55px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-box:hover {
    --tw-shadow: 0px 35px 65px -10px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 35px 65px -10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-box13:hover {
    --tw-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);
    --tw-shadow-colored: 0px 15px 30px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-box6:hover {
    --tw-shadow: 0px 35px 55px -10px rgba(239, 87, 67, 0.15);
    --tw-shadow-colored: 0px 35px 55px -10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-box3:hover {
    --tw-shadow: 0px 10px 40px rgba(0, 0, 0, 0.08);
    --tw-shadow-colored: 0px 10px 40px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-box8:hover {
    --tw-shadow: 0px 35px 55px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 35px 55px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:ring-2:hover {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:ring-primary:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(184 145 72 / var(--tw-ring-opacity));
}

.hover\:grayscale-0:hover {
    --tw-grayscale: grayscale(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.focus\:border-\[\#B2E2DF\]:focus {
    --tw-border-opacity: 1;
    border-color: rgb(178 226 223 / var(--tw-border-opacity));
}

.focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-border-opacity));
}

.focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgb(12 19 39 / var(--tw-border-opacity));
}

.focus\:border-transparent:focus {
    border-color: transparent;
}

.focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.focus\:font-normal:focus {
    font-weight: 400;
}

.focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus\:outline-0:focus {
    outline-width: 0px;
}

.focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity));
}

.focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(12 19 39 / var(--tw-ring-opacity));
}

.focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

.focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
}

.focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
}

.focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
}

.focus\:hover\:font-light:hover:focus {
    font-weight: 300;
}

.focus\:hover\:shadow-md:hover:focus {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-visible\:shadow-md:focus-visible {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.active\:shadow-md:active {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.disabled\:font-bold:disabled {
    font-weight: 700;
}

.disabled\:shadow-md:disabled {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:first-child .group-first\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:last-child .group-last\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:nth-child(odd) .group-odd\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:nth-child(even) .group-even\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:visited .group-visited\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:checked .group-checked\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:focus-within .group-focus-within\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(12 19 39 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-\[\#FFE8E8\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 232 232 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-paste {
    --tw-bg-opacity: 1;
    background-color: rgb(233 251 250 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-\[\#E8F5FF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(232 245 255 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-\[\#E3F9F6\] {
    --tw-bg-opacity: 1;
    background-color: rgb(227 249 246 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-\[\#EEE8FF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(238 232 255 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-\[\#FFF6EB\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 246 235 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-\[\#E8FFEA\] {
    --tw-bg-opacity: 1;
    background-color: rgb(232 255 234 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-opacity-\[0\.1\] {
    --tw-bg-opacity: 0.1;
}

.group:hover .group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-primary {
    --tw-text-opacity: 1;
    color: rgb(184 145 72 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-100 {
    opacity: 1;
}

.group:hover .group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:focus-within\:text-left:focus-within {
    text-align: left;
}

.group:focus .group-focus\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:focus-visible .group-focus-visible\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:active .group-active\:opacity-10 {
    opacity: 0.1;
}

.group:active .group-active\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:disabled .group-disabled\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

[dir="ltr"] .ltr\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

[dir="rtl"] .rtl\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

[dir="rtl"] .rtl\:active\:text-center:active {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .motion-safe\:shadow-md {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .motion-safe\:transition {
        transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }

    .motion-safe\:hover\:text-center:hover {
        text-align: center;
    }
}

@media (prefers-reduced-motion: reduce) {
    .motion-reduce\:shadow-md {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .motion-reduce\:transition {
        transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }
}

@media (prefers-color-scheme: dark) {
    .dark\:shadow-md {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .dark\:focus\:text-left:focus {
        text-align: left;
    }
}

@media (min-width: 640px) {
    .sm\:container {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-right: 15px;
        padding-left: 15px;
    }

    @media (min-width: 640px) {
        .sm\:container {
            max-width: 640px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @media (min-width: 768px) {
        .sm\:container {
            max-width: 768px;
        }
    }

    @media (min-width: 1024px) {
        .sm\:container {
            max-width: 1024px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @media (min-width: 1140px) {
        .sm\:container {
            max-width: 1140px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @media (min-width: 1170px) {
        .sm\:container {
            max-width: 1170px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .sm\:block {
        display: block;
    }

    .sm\:flex {
        display: flex;
    }

    .sm\:w-\[159px\] {
        width: 159px;
    }

    .sm\:w-\[200px\] {
        width: 200px;
    }

    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .sm\:flex-row {
        flex-direction: row;
    }

    .sm\:space-x-6> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.5rem * var(--tw-space-x-reverse));
        margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .sm\:space-y-0> :not([hidden])~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }

    .sm\:space-x-5> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.25rem * var(--tw-space-x-reverse));
        margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .sm\:text-center {
        text-align: center;
    }

    .sm\:font-bold {
        font-weight: 700;
    }

    .sm\:tabular-nums {
        --tw-numeric-spacing: tabular-nums;
        font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
    }

    .sm\:shadow-md {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .sm\:active\:shadow-md:active {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    @media (prefers-reduced-motion: no-preference) {
        .group:active .sm\:motion-safe\:group-active\:focus\:opacity-10:focus {
            opacity: 0.1;
        }
    }
}

@media (min-width: 768px) {
    .md\:container {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-right: 15px;
        padding-left: 15px;
    }

    @media (min-width: 640px) {
        .md\:container {
            max-width: 640px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @media (min-width: 768px) {
        .md\:container {
            max-width: 768px;
        }
    }

    @media (min-width: 1024px) {
        .md\:container {
            max-width: 1024px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @media (min-width: 1140px) {
        .md\:container {
            max-width: 1140px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @media (min-width: 1170px) {
        .md\:container {
            max-width: 1170px;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .md\:col-span-2 {
        grid-column: span 2 / span 2;
    }

    .md\:col-span-8 {
        grid-column: span 8 / span 8;
    }

    .md\:col-span-4 {
        grid-column: span 4 / span 4;
    }

    .md\:mb-10 {
        margin-bottom: 2.5rem;
    }

    .md\:mt-14 {
        margin-top: 3.5rem;
    }

    .md\:mt-0 {
        margin-top: 0px;
    }

    .md\:mt-12 {
        margin-top: 3rem;
    }

    .md\:mb-0 {
        margin-bottom: 0px;
    }

    .md\:block {
        display: block;
    }

    .md\:flex {
        display: flex;
    }

    .md\:h-\[56px\] {
        height: 56px;
    }

    .md\:w-auto {
        width: auto;
    }

    .md\:w-\[56px\] {
        width: 56px;
    }

    .md\:max-w-4xl {
        max-width: 56rem;
    }

    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .md\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .md\:flex-row {
        flex-direction: row;
    }

    .md\:space-x-6> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.5rem * var(--tw-space-x-reverse));
        margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .md\:space-y-0> :not([hidden])~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }

    .md\:space-x-4> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem * var(--tw-space-x-reverse));
        margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .md\:space-x-10> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(2.5rem * var(--tw-space-x-reverse));
        margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .md\:py-20 {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .md\:pt-10 {
        padding-top: 2.5rem;
    }

    .md\:pt-20 {
        padding-top: 5rem;
    }

    .md\:pb-20 {
        padding-bottom: 5rem;
    }

    .md\:text-center {
        text-align: center;
    }

    .md\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }

    .md\:text-6xl {
        font-size: 3.75rem;
        line-height: 1;
    }

    .md\:font-bold {
        font-weight: 700;
    }

    .md\:leading-\[72px\] {
        line-height: 72px;
    }

    .md\:opacity-50 {
        opacity: 0.5;
    }

    .md\:shadow-sm {
        --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
        --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .md\:shadow-md {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .md\:hover\:text-center:hover {
        text-align: center;
    }

    .md\:hover\:text-right:hover {
        text-align: right;
    }

    .md\:hover\:opacity-20:hover {
        opacity: 0.2;
    }

    .group:focus .md\:group-focus\:shadow-md {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    @media (prefers-reduced-motion: no-preference) {
        .md\:motion-safe\:hover\:transition:hover {
            transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
            transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
            transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;
        }
    }

    @media (min-width: 640px) {
        .md\:sm\:text-center {
            text-align: center;
        }
    }
}

@media (min-width: 1024px) {
    .lg\:relative {
        position: relative;
    }

    .lg\:sticky {
        position: sticky;
    }

    .lg\:top-10 {
        top: 2.5rem;
    }

    .lg\:col-span-6 {
        grid-column: span 6 / span 6;
    }

    .lg\:col-span-8 {
        grid-column: span 8 / span 8;
    }

    .lg\:col-span-4 {
        grid-column: span 4 / span 4;
    }

    .lg\:my-6 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .lg\:mr-10 {
        margin-right: 2.5rem;
    }

    .lg\:mb-\[150px\] {
        margin-bottom: 150px;
    }

    .lg\:mb-0 {
        margin-bottom: 0px;
    }

    .lg\:mb-10 {
        margin-bottom: 2.5rem;
    }

    .lg\:-mt-20 {
        margin-top: -5rem;
    }

    .lg\:mt-\[136px\] {
        margin-top: 136px;
    }

    .lg\:-mt-\[250px\] {
        margin-top: -250px;
    }

    .lg\:mt-10 {
        margin-top: 2.5rem;
    }

    .lg\:block {
        display: block;
    }

    .lg\:flex {
        display: flex;
    }

    .lg\:grid {
        display: grid;
    }

    .lg\:hidden {
        display: none;
    }

    .lg\:h-\[500px\] {
        height: 500px;
    }

    .lg\:h-\[400px\] {
        height: 400px;
    }

    .lg\:h-\[64px\] {
        height: 64px;
    }

    .lg\:w-auto {
        width: auto;
    }

    .lg\:w-1\/2 {
        width: 50%;
    }

    .lg\:w-full {
        width: 100%;
    }

    .lg\:w-\[64px\] {
        width: 64px;
    }

    .lg\:max-w-\[270px\] {
        max-width: 270px;
    }

    .lg\:max-w-\[470px\] {
        max-width: 470px;
    }

    .lg\:max-w-\[770px\] {
        max-width: 770px;
    }

    .lg\:flex-none {
        flex: none;
    }

    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .lg\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .lg\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .lg\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .lg\:grid-cols-\[200px\2c repeat\(auto-fill\2c minmax\(15\%\2c 100px\)\)\2c 300px\] {
        grid-template-columns: 200px repeat(auto-fill, minmax(15%, 100px)) 300px;
    }

    .lg\:flex-row {
        flex-direction: row;
    }

    .lg\:items-center {
        align-items: center;
    }

    .lg\:justify-start {
        justify-content: flex-start;
    }

    .lg\:justify-end {
        justify-content: flex-end;
    }

    .lg\:justify-between {
        justify-content: space-between;
    }

    .lg\:gap-10 {
        gap: 2.5rem;
    }

    .lg\:space-x-\[70px\]> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(70px * var(--tw-space-x-reverse));
        margin-left: calc(70px * calc(1 - var(--tw-space-x-reverse)));
    }

    .lg\:space-x-10> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(2.5rem * var(--tw-space-x-reverse));
        margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .lg\:space-y-10> :not([hidden])~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
    }

    .lg\:space-y-0> :not([hidden])~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }

    .lg\:space-x-8> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(2rem * var(--tw-space-x-reverse));
        margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .lg\:space-x-16> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(4rem * var(--tw-space-x-reverse));
        margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .lg\:py-28 {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }

    .lg\:pt-10 {
        padding-top: 2.5rem;
    }

    .lg\:pt-0 {
        padding-top: 0px;
    }

    .lg\:pr-\[10px\] {
        padding-right: 10px;
    }

    .lg\:pl-\[38px\] {
        padding-left: 38px;
    }

    .lg\:pt-16 {
        padding-top: 4rem;
    }

    .lg\:pb-20 {
        padding-bottom: 5rem;
    }

    .lg\:pt-14 {
        padding-top: 3.5rem;
    }

    .lg\:text-left {
        text-align: left;
    }

    .lg\:text-center {
        text-align: center;
    }

    .lg\:text-2xl {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .lg\:text-\[34px\] {
        font-size: 34px;
    }

    .lg\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .lg\:text-\[77px\] {
        font-size: 77px;
    }

    .lg\:text-\[44px\] {
        font-size: 44px;
    }

    .lg\:font-bold {
        font-weight: 700;
    }

    .lg\:leading-\[51px\] {
        line-height: 51px;
    }

    .lg\:leading-\[106\.4px\] {
        line-height: 106.4px;
    }

    .lg\:leading-\[36px\] {
        line-height: 36px;
    }

    .lg\:leading-\[61px\] {
        line-height: 61px;
    }

    .lg\:\!opacity-50 {
        opacity: 0.5 !important;
    }

    .lg\:shadow-md {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    @media (prefers-color-scheme: dark) {
        .lg\:dark\:shadow-md {
            --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
            --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    }
}

@media (min-width: 1280px) {
    .xl\:right-\[-260px\] {
        right: -260px;
    }

    .xl\:col-span-7 {
        grid-column: span 7 / span 7;
    }

    .xl\:col-span-5 {
        grid-column: span 5 / span 5;
    }

    .xl\:col-span-3 {
        grid-column: span 3 / span 3;
    }

    .xl\:col-span-6 {
        grid-column: span 6 / span 6;
    }

    .xl\:mr-\[74px\] {
        margin-right: 74px;
    }

    .xl\:mb-\[205px\] {
        margin-bottom: 205px;
    }

    .xl\:block {
        display: block;
    }

    .xl\:h-\[652px\] {
        height: 652px;
    }

    .xl\:h-\[593px\] {
        height: 593px;
    }

    .xl\:h-\[470px\] {
        height: 470px;
    }

    .xl\:min-h-screen {
        min-height: 100vh;
    }

    .xl\:w-\[80\%\] {
        width: 80%;
    }

    .xl\:w-1\/3 {
        width: 33.333333%;
    }

    .xl\:max-w-\[570px\] {
        max-width: 570px;
    }

    .xl\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .xl\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .xl\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .xl\:justify-end {
        justify-content: flex-end;
    }

    .xl\:gap-\[70px\] {
        gap: 70px;
    }

    .xl\:gap-\[60px\] {
        gap: 60px;
    }

    .xl\:gap-0 {
        gap: 0px;
    }

    .xl\:space-x-10> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(2.5rem * var(--tw-space-x-reverse));
        margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
    }

    .xl\:space-y-0> :not([hidden])~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }

    .xl\:space-x-\[39px\]> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(39px * var(--tw-space-x-reverse));
        margin-left: calc(39px * calc(1 - var(--tw-space-x-reverse)));
    }

    .xl\:py-\[174px\] {
        padding-top: 174px;
        padding-bottom: 174px;
    }

    .xl\:pt-\[297px\] {
        padding-top: 297px;
    }

    .xl\:pb-40 {
        padding-bottom: 10rem;
    }

    .xl\:pb-\[136px\] {
        padding-bottom: 136px;
    }

    .xl\:pb-\[130px\] {
        padding-bottom: 130px;
    }

    .xl\:pt-\[129px\] {
        padding-top: 129px;
    }

    .xl\:shadow-md {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .xl\:focus\:disabled\:\!float-right:disabled:focus {
        float: right !important;
    }
}

@media (min-width: 1536px) {
    .\32xl\:shadow-md {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    @media (prefers-color-scheme: dark) {
        @media (prefers-reduced-motion: no-preference) {
            .\32xl\:dark\:motion-safe\:focus-within\:shadow-md:focus-within {
                --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
                --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
            }
        }
    }
}

@media (orientation: portrait) {
    .portrait\:text-center {
        text-align: center;
    }
}

.right-0 {
    right: 0;
}

.service-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 495px) {
    .service-tabs {
        grid-template-columns: 1fr;
    }
}

.category-card {
    display: grid;
    grid-template-columns: 1fr 4fr;
}

@media screen and (max-width: 605px) {
    .category-card {
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
    }

    .cat-cardimg {
        margin-left: 25px;
        margin-bottom: 20px;
    }
}

.r-txtn {
    font-size: 1.5em;
    color: #131313;
    font-weight: 600;
    word-spacing: 3px;
    letter-spacing: 1px;
    line-height: 35px;
}



/* intro  */
/* intro  */
/* intro  */


.intro-main {
    min-height: 85vh;
    background-repeat: space;
    background-size: 30%;
}

.text-cont-int {
    text-align: center;
    font-size: 1.5em;
    font-weight: 600;
    color: #B89148;
    line-height: 45px;
    word-spacing: 2px;
    text-align: justify;
}

.cl-bg {
    background-image: url(../images/logo/herosectionbg.webp);
}

.fil-treat {
    font-size: 1.4em;
}

.card-head {
    font-size: 1em;
    font-weight: bolder;
    text-align: center;
}

.card-content {
    line-height: 25px;
    letter-spacing: 1px;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
    word-spacing: 2px;
}

.t-card {
    padding: 15px 15px;
}

.cons-textn {
    font-size: 1.5rem;
    line-height: 35px;
    color: #2b2a28;
    word-spacing: 2px;
}

.cons-high {
    color: #b89148;
    font-weight: bolder;
}

.cons-img {
    width: 320px;
}

.singlecard-prev {
    font-size: 1.5em;
    line-height: 40px;
    word-spacing: 2px;
    margin-top: 20px;
}

.blog-content {
    font-size: 1.2em;
    line-height: 40px;
    color: #2b2a28;
}

.cont-content {
    font-size: 1.2em;
    color: #2b2a28;
    line-height: 32px;
}

.cont-footer-content {
    font-size: 1.2em;
    color: white;
    line-height: 32px;
}

.intro-img {
    width: 30%;
}


.logo-head {
    width: 180px;
    height: 100%;
}



.team-card {
    width: 100%;
    height: fit-content;
    max-height: 46vh;
}

.team-dettext {
    font-size: 1.2em;
    color: #2b2a28;
    word-spacing: 5px;
    line-height: 30px;
}

.tech-text {
    color: #2b2a28;
    font-size: 1em;
}

.ban-cont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 90vh;
    margin-top: 50px;
}

.ban-contone {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-flow: column;
    gap: 20px;
    padding: 20px;
    margin-top: 50px;
}

.ban-conttwo {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-flow: column;
}

.ban-img {
    width: 650px;
    position: absolute;
    margin-top: 5px;
}

.serv-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.serv-tone {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-flow: column;
}

.serv-ttwo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ban-contone-heading-v {
    font-size: 60px;
}

.res-txt {
    border: 2px solid #b89148;
    color: #b89148;
    /* font-size: larger; */
    padding: 3px 10px;
    font-weight: bolder;
    border-radius: 5px;
    width: "100px"
}


.book-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    border: 1px solid #5f5f5f;
    padding: 10px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.bookcard-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}


.res-head {
    font-size: larger;
}

.my-swiper {
    width: 400px;
    margin: auto;
    position: relative;
}

@media screen and (max-width: 760px) {
    .serv-tabs {
        grid-template-columns: 1fr;
    }

    .serv-tone {
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 1279px) {
    .ban-img {
        width: 600px;
    }
}

@media screen and (max-width: 1103px) {
    .ban-img {
        width: 500px;
    }
}

@media screen and (max-width: 950px) {
    .ban-img {
        width: 500px;
        margin-top: 500px;
    }

    .ban-cont {
        grid-template-columns: 1fr;
    }

    .ban-contone {
        order: 1;
        align-items: center;
        margin-top: 550px;
        text-align: center;
    }

    .ban-conttwo {
        order: 0;
        align-items: center;
    }

    .ban-contone-heading {
        font-size: 2em;
    }

    .ban-contone-heading-v {
        font-size: 1.7em;
        line-height: 35px;
    }

    .bookcard-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .my-swiper {
        width: 400px;
        position: relative;
        margin: auto;
    }
}

@media screen and (max-width: 605px) {
    .my-swiper {
        width: 300px;
        position: relative;
        margin: auto;
    }

    .intro-img {
        width: 70%;
    }

    .text-cont {
        font-size: 1.2em;
        line-height: 32px;

    }

    .logo-head {
        width: 120px;
        height: 100%;
    }

    .ban-img {
        width: 300px;
        margin-top: 420px;
    }

    .ban-cont {
        grid-template-columns: 1fr;
        margin-top: 0px;
    }

    .ban-contone {
        align-items: center;
        text-align: center;
        order: 1;
        margin-top: 300px;
    }

    .ban-conttwo {
        align-items: center;
        order: 0;
        margin-top: 10px;
        margin-left: 30px;
    }

    .ban-contone-heading {
        font-size: 1.3em;
        padding-top: 50px;
    }

    .ban-contone-heading-v {
        font-size: 1.4em;
        padding-top: 80px;
        line-height: 26px;
    }

    .r-txtn {
        font-size: 1.2em;
        line-height: 23px;
    }

    .text-cont-int {
        font-size: 1.2em;
        line-height: 30px;
    }

    .inprev-des {
        font-size: 1.2em;
        line-height: 30px;
    }

    .cont-content {
        font-size: 1em;
        line-height: 25px;
    }

    .filter-list {
        font-size: 0.8em;
    }

    .fil-treat {
        font-size: 1.4em;
    }

    .bookcard-grid {
        grid-template-columns: 1fr 1fr;
    }

    .res-head {
        font-size: medium;
    }
}

@media screen and (max-width: 900px) {
    .bookcard-grid {
        grid-template-columns: 1fr;
    }

}

@media screen and (max-width: 900px) {
    .text-cont {
        font-size: 1.5em;
        line-height: 38px;
    }
}

.course-home-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.course-home-page-box {
    box-sizing: border-box;
    padding: 20px;
    margin: 5px;
    text-align: center;
    flex: 1 0 20%;
    /* Adjust the flex property as needed */
}


/* Tablet view - 2 divs in one row */
@media (max-width: 991px) and (min-width: 768px) {
    .course-home-page-box {
        flex: 1 0 48%;
    }
}

.offer-card {
    display: flex;
    align-items: center;
    justify-content: "center";
    gap: 20px;
    padding: 20px;
    flex-direction: column;
}

.offer-model {
    margin: 5%;
    font-size: 14px;
}

@media (min-width:776px) {
    .offer-card {
        flex-direction: row;
    }

    .offer-model {
        margin: 20%;
        width: 100%;
        font-size: 18px;
    }

    .offer-card {
        flex-direction: row;

    }

    .offer-card .media {
        width: 65%
    }

    .text-title {
        width: 35%
    }

    .offer-card img {
        width: 100%;


    }

}

@media (min-width: 1025px) {
    .offer-card {
        flex-direction: row;

    }

    .offer-card .media {
        width: 65%
    }

    .text-title {
        width: 35%
    }

    .offer-card img {
        width: 100%;


    }

    .offer-model {
        margin: 20%;
        width: 55%;
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .course-home-page-box {
        flex: 1 0 100%;
    }
}

.styles-module_accountName__20AXD {
    color: green !important;
}

.ReactModalPortal {
    z-index: 1000;
    position: absolute;
}

.modal-btn-section {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.modal-btn {
    padding: 5px 15px;
    border-radius: 5px;
}   

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .announdement-img {
        width: 85vw;
    }
}

@media only screen and (min-width: 600px) {
    .announdement-img {
        height: 80vh;
        z-index: 1000;
    }
}

.react-datepicker__day--selected {
    background-color: rgb(184, 145, 72) !important;
}

.react-datepicker-wrapper {
    width: 100% !important;

}

.react-datepicker__input-container input {
    border-radius: 0.25em;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.react-datepicker__input-container input:focus {
    border: 1px solid rgb(184 145 72) !important;
}